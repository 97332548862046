// html, body, div, span, applet, object, iframe,
// h1, h2, h3, h4, h5, h6, p, blockquote, pre,
// a, abbr, acronym, address, big, cite, code,
// del, dfn, em, img, ins, kbd, q, s, samp,
// small, strike, strong, sub, sup, tt, var,
// b, u, i, center,
// dl, dt, dd, ol, ul, li,
// fieldset, form, label, legend,
// table, caption, tbody, tfoot, thead, tr, th, td,
// article, aside, canvas, details, embed, 
// figure, figcaption, footer, header, hgroup, 
// menu, nav, output, ruby, section, summary,
// time, mark, audio, video {
// 	margin: 0;
// 	padding: 0;
// 	border: 0;
// 	font-size: 100%;
// 	font: inherit;
// 	vertical-align: baseline;
// 	box-sizing: border-box;
// }
// /* HTML5 display-role reset for older browsers */
// article, aside, details, figcaption, figure, 
// footer, header, hgroup, menu, nav, section {
// 	display: block;
// }
// body {
// 	line-height: 1;
// }
// ol, ul {
// 	list-style: none;
// }
// blockquote, q {
// 	quotes: none;
// }
// blockquote:before, blockquote:after,
// q:before, q:after {
// 	content: '';
// 	content: none;
// }
// table {
// 	border-collapse: collapse;
// 	border-spacing: 0;
// }

#root {
	width: 100%;
}

.app {
	min-height: 100vh;
	background-color: lightblue;
}

.z-0 {
	z-index: 0;
}
.z-10 {
	z-index: 10;
}
.z-100 {
	z-index: 100;
}

.top-menu {
	height: 4rem;
}

.pic-mobile {
	width: 40%;
}

.pic-desctop {
	width: 20%;
}

// ReSet bootstrap variables and compile bootstrap from scss source 
$primary: #873875;
$body-color: #000;

$enable-negative-margins: true;

@import 'bootstrap/scss/bootstrap.scss';