// For use bootstrap vars and mixins inside
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.wrapper {
	width: 1127px;
	height: 797px;
	overflow: hidden;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
}

.santa {
	flex: 0 0 45%;
	height: 100%;
	position: relative;
	&-img {
		height: 100%;
	}
}

.logo-img {
	position: absolute;
	left: 10px;
	bottom: 10px;
	width: 128px;
}

.frame {
	flex: 0 0 55%;
	position: relative;
	height: 100%;
	&-img {
		display: block;
		position: absolute;
		width: 100%;
		top: 5rem;
	}

	&-title {
		position: absolute;
		top: 0.6rem;
		width: 100%;
		font-size: 2rem;
		text-align: center;
		font-weight: 500;
		color: #2FB6BC;
	}
	&-text {
		position: absolute;
		top: 3rem;
		width: 100%;
		font-size: 1.5rem;
		text-align: center;
		font-weight: 500;
		color: #2FB6BC;
		z-index: 10;
	}
}

.list {
	position: absolute;
	top: 9rem;
	left: 5.5rem;
	max-width: 28rem;
	list-style: none;
	font-size: 1rem;

	&-item {
		padding: 0.25rem 0.5rem;
		line-height: 1.15;
		margin-bottom: 0.25rem;

		&:nth-child(odd) {
			background-color: #fff;
		}
	}
}
