// For use bootstrap vars and mixins inside
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.wrapper {
	// height: 100%;
	// padding: 0 2rem;
}

.drop-to {
	display: none;

	@include media-breakpoint-up(xl) {
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		height: auto;
		width: auto;
	}	

}

.drop-from {
	position: relative;
}

.image-wrapper {
	border: 1px solid red;
	position: relative;
}

.drop-area-fireplace {
	position: absolute;
	left: 13%;
	right: 11%;
	top: 35%;
	bottom: 8%;
	// border: 1px solid black;
	padding: 0;
	margin: 0;

	// &::before {
	// 	content: "";
	// 	display: block;
	// 	position: absolute;
	// 	left: 24%;
	// 	bottom: 9%;
	// 	width: 51%;
	// 	height: 54%;
	// 	box-shadow: 30px -30px 60px 12px rgb(255 202 104 / 33%),
	// 					-30px -30px 60px 12px rgb(255 202 104 / 33%);
	// }
}

.drop-area-tree {
	position: absolute;
	top: 15%;
	bottom: 14%;
	left: 0;
	right: 0;
	padding: 0;
	margin: 0;
	// border: 1px solid black;

	// width: 500px;
	// height: 800px;
	// position: absolute;
	// left: 24%;
	// bottom: 10%;
}

.habits-list {
	// width: 587px;
	padding: 0;
	margin: 0;
	max-height: 70vh;
	overflow-y: auto;
}

.habit {
	position: relative;
	width: 100%;
	min-height: 4rem;
	// height: 72px;
	background: #fefefe;
	// margin-bottom: 0.5rem;
	border-bottom: 1px solid lightgray;
	padding: 0.5rem;
	display: flex;
	column-gap: 1rem;
	align-items: center;
	user-select: none;

	img {
		height: 2.5rem;
	}

	&-text {
		// font-weight: 400;
		font-size: 1.25rem;
		line-height: 1.15;
		margin: 0;
	}

	&-link {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1.5rem;
		line-height: 1;
		color: #fff;
	}
}

.chosen-habit {
	background: #545454;
	cursor: pointer;
}
