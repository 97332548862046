// For use bootstrap vars and mixins inside
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.main-wrapper {
	position: relative;
	width: 100%;
	min-height: 100vh;
}

.background-wrapper {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100vw;
	height: 30vh;
	overflow: hidden;
	@include media-breakpoint-up(xl) {
		z-index: 1;
		height: 100vh;
	}
}

.background {
	position: absolute;
	z-index: 0;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: lightblue;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	background-image: url('../../assets/img/main-page-bg.png');
}

.logo {
	position: absolute;
	top: 0.5rem;
	left: 0.5rem;
	cursor: pointer;
	z-index: 50;
}

.row-header {
	height: 30vh;
	position: sticky;
	top: 0;
	z-index: 20;
	margin-left: -0.75rem;
	margin-right: -0.75rem;	
	@include media-breakpoint-up(xl) {
		height: 10rem;
	}
}

.row-main {
	flex-direction: column;
	flex-wrap: nowrap;
	margin-left: -0.75rem;
	margin-right: -0.75rem;
	@include media-breakpoint-up(xl) {
		flex-direction: row;
		flex-wrap: wrap;
	}
}

.main-container {
	display: flex;
	flex-direction: column;
}

.wrapper {
	min-height: 100vh;
}

.left-side {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	@include media-breakpoint-up(xl) {
    padding-top: 4rem;
    position: relative;
    top: auto;
    z-index: 2;
	}
}

.right-side {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	@include media-breakpoint-up(xl) {
    padding-top: 4rem;
    position: relative;
    top: auto;
    z-index: 2;
	}
}

.habits-header-bad {
	position: sticky;
	top: 30vh;
	z-index: 10;
	width: 100%;
	margin: 0;
	padding: 0.5rem 1rem;
	border-radius: 0 0;
	text-align: center;
	color: whitesmoke;
	background: #CE1C51;	
	@include media-breakpoint-up(xl) {
		position: relative;
		top: auto;
		padding: 1.5rem 1rem;
		border-radius: 0.5rem 0.5rem 0 0;
	}
}

.habits-header-good {
	position: sticky;
	top: 30vh;
	z-index: 10;
	width: 100%;
	margin: 0;
	padding: 0.5rem 1rem;
	border-radius: 0 0;
	text-align: center;
	color: whitesmoke;
	background: #2FB6BC;
	@include media-breakpoint-up(xl) {
		position: relative;
		top: auto;
		padding: 1.5rem 1rem;
		border-radius: 0.5rem 0.5rem 0 0;
	}
}

.habits-block {
	background: #fff;
	padding: 0.25rem;
	width: 100%;
	&:first-child {
		background-color: lightgrey;
	}
}

.habit {
	&-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0.5rem;
		&:nth-child(odd) {
			background-color: #f5f5f5;
		}
	}
	&-name {
		margin-bottom: .2rem;
	}
}

.delete-inner {
	width: 500px;
	margin: 0 auto;
}

.my-swiper {
	border: 1px solid black;
}
