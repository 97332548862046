// For use bootstrap vars and mixins inside
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.modal-window {
	position: absolute;
	z-index: 10;
	left: 0;
	right: 0;
	top: 50%;
	transform: translate(0, -50%);


	// position: absolute;
	// left: 5%;
	// bottom: 10%;
	// display: flex;
	// flex-direction: column;
	// justify-content: center;
	// align-items: center;
	// width: 792px;
	// height: 303px;
	// background: #f8efef;
	// border: 1px solid #c00f0f;
	// &-text {
	// 	max-width: 680px;
	// 	margin-bottom: 25px;
	// 	font-size: 35px;
	// 	line-height: 42px;
	// 	text-align: center;
	// 	color: #8a1c1c;
	// }
}

.modal-container {
	pointer-events: auto;

	padding: 2rem 2rem 2.5rem;
	// margin: 0.5rem;

	// background: rgba(217, 217, 217, 0.8);
	background: rgba(248, 239, 239, 0.95);
	color: #8a1c1c;
	border: 1px solid #8a1c1c;
	text-align: center;

	@include media-breakpoint-up(xl) {
		padding: 2rem 6rem 2.5rem;
	}
}