// For use bootstrap vars and mixins inside
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";


.main-wrapper {
	position: relative;
	width: 100%;
	min-height: 100vh;

}

.background-wrapper {
	position: fixed;
	z-index: 10;
	top: 0;
	left: 0;
	width: 100vw;
	height: 30vh;
	overflow: hidden;
	@include media-breakpoint-up(xl) {
		z-index: 1;
		height: 100vh;
	}			
}

.background {
	position: absolute;
	z-index: 0;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;

	background-color: lightblue;
	
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	
	background-image: url('../../assets/img/fireplace-bg.svg');
}

.logo {
	position: absolute;
	top: 0.5rem;
	left: 0.5rem;
	cursor: pointer;
	z-index: 50;
}

.row-main {
	flex-direction: column;
	flex-wrap: nowrap;
	margin-left: -0.75rem;
	margin-right: -0.75rem;
	
	@include media-breakpoint-up(xl) {
		height: 100vh;
		flex-direction: row;
		flex-wrap: wrap;
	}		
}

.left-side {
	position: sticky;
	top: 0;
	height: 30vh;
	z-index: 10;

	@include media-breakpoint-up(xl) {
    z-index: 2;
    position: relative;
    top: auto;
    max-height: 100%;
    height: 100vh;
	}
}

.left-image {
	display: none;
	@include media-breakpoint-up(xl) {
		display: block;
		width: 90%;
	}
}

.inner {
	// height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	
	height: 100%;
	position: relative;

	@include media-breakpoint-up(xl) {
		justify-content: start;
		position: relative;
		// z-index: 1;
		height: 100vh;
		width: 90%;
	}		
}



.right-side {
	display: flex;
	flex-direction: column;
	align-items: center;
	
	position: sticky;
	top: 30vh;
	
	@include media-breakpoint-up(xl) {
		min-height: 100vh;
		padding-top: 4rem;
		position: relative;
		top: auto;
		z-index: 2;
	}	
}




.wrapper {
	min-height: 100vh;
	// background-image: url('../../assets//img//santa-background.jpg');
	background-image: url('../../assets/img/fireplace-bg.svg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}




.right-btn {
	width: 380px;
	height: 95px;
	background: #873875;
	border-radius: 25px 0px 0px 25px;
	font-size: 40px;
	line-height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fffbfb;
	border: none;
	align-self: flex-end;
	margin-right: -12px;
}



.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	
	@include media-breakpoint-up(xl) {
		justify-content: end;
	}		
}

.text-content {
	background: #2FB6BC;
	// background: rgba(138, 43, 226, 0.8);
	// border: 1px solid blueviolet;
	width: 100%;

	position: sticky;
	top: 30vh;
	z-index: 10;
	padding: 0.5rem 1rem;
	text-align: center;
	color: whitesmoke;
	
	@include media-breakpoint-up(xl) {
		position: relative;
		top: auto;
		padding: 1.5rem 1rem;
		border-radius: 0.5rem 0.5rem 0 0;
	}		

}

.habits-list {
	padding: 0;
	margin: 0;
	z-index: 2;
	
	@include media-breakpoint-up(xl) {
		overflow-y: auto;
		max-height: 70vh;

	}			
}

.habit {
	position: relative;
	width: 100%;
	min-height: 4rem;
	background: #fefefe;
	border-bottom: 1px solid lightgray;
	padding: 0.5rem;
	display: flex;
	column-gap: 1rem;
	align-items: center;
	-webkit-user-select: none;
	user-select: none;

	&-text {
    font-size: 1.25rem;
    line-height: 1.15;
    margin: 0;
	}

	img {
		width: 2rem;
	}
}
