.footer {
	text-align: center;
	padding-bottom: 1rem;
}

.evaluation-title {
	text-align: center;
	margin: 0 auto;
}

.stars {
	width: fit-content;
	margin: 0 auto;
}
