// For use bootstrap vars and mixins inside
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.wrapper {
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 2rem 0;
	background-color: lightblue;
	background-image: url('../../assets/img/main-page-bg.png');
	background-repeat: no-repeat;
	background-position: 50% 30%;
	background-size: cover;
}

.logo {
	position: absolute;
	top: 0.5rem;
	left: 0.5rem;
	cursor: pointer;
	z-index: 50;
}

.content-window {
	padding: 2rem 2rem 2.5rem;
	background: rgba(217, 217, 217, 0.8);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	@include media-breakpoint-up(xl) {
		padding: 2rem 6rem 2.5rem;
	}
}

.list-item {
	font-size: 1rem;
	line-height: 1.25;
	margin-bottom: 1rem;
	@include media-breakpoint-up(xl) {
		font-size: 1.25rem;
	}		
}
