// For use bootstrap vars and mixins inside
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";


.modal-container {
	pointer-events: auto;
	padding: 1rem 1rem 0.5rem;
	// margin: 0.5rem;

	// background: rgba(217, 217, 217, 0.8);
	color: #8a1c1c;
	
	@include media-breakpoint-up(xl) {
		background: rgba(248, 239, 239, 0.95);
		border: 1px solid #8a1c1c;
		text-align: center;

		padding: 2rem 2rem 2rem;
	}
}

.modal-window {
	position: absolute;
	bottom: 0;
	padding: 0;
	
	@include media-breakpoint-up(xl) {
		position: absolute;
		z-index: 10;
		left: 0;
		right: 0;
		top: 65%;
		transform: translate(0, -50%);
	}

}

.modal-window-text {

	font-size: 1rem;
	line-height: 1.25;
	text-align: center;

	@include media-breakpoint-up(xl) {
		font-size: calc(1.275rem + 0.3vw) !important;
	}	
}