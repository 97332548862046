// For use bootstrap vars and mixins inside
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

// background for habits pages
.main-wrapper {
	position: relative;
	width: 100%;
	min-height: 100vh;

}

.background-wrapper {
	position: fixed;
	z-index: 10;
	top: 0;
	left: 0;
	width: 100vw;
	height: 30vh;
	overflow: hidden;
	@include media-breakpoint-up(xl) {
		z-index: 1;
		height: 100vh;
	}			
}

.background {
	position: absolute;
	z-index: 0;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;

	background-color: lightblue;
	
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	
	&-good {
		background-image: url('../../assets/img/armchair-bg.png');
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;

		// background: url('../../assets/img/table-with-box.svg'), url('../../assets/img/armchair-bg.png');
		// background-repeat: no-repeat;
		// background-position: 53% 120%, 100% 55%;
		// background-size: auto 50%, 120% auto;
	}
	&-bad {
		background-image: url('../../assets/img/armchair-bg.png');
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;

		// background-image: url('../../assets/img/tree-bg.svg'), url('../../assets/img/fireplace-bg.svg');
		// background-repeat: no-repeat;
		// background-position: right bottom, right;
		// background-size: auto 145%, 120% auto;

	}
}

.row-main {
	flex-direction: column;
	flex-wrap: nowrap;
	margin-left: -0.75rem;
	margin-right: -0.75rem;
	
	@include media-breakpoint-up(xl) {
		height: 100vh;
		flex-direction: row;
		flex-wrap: wrap;
	}		
}

.left-side {
	position: sticky;
	top: 0;
	height: 30vh;
	z-index: 10;
	
	@include media-breakpoint-up(xl) {
		z-index: 2;
		position: relative;
		top: auto;
		max-height: 100%;
		height: 100vh;
	}	
}

.logo {
	position: absolute;
	top: 0.5rem;
	left: 0.5rem;
	cursor: pointer;
	z-index: 50;
}

.inner {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	// margin-bottom: 2rem;
	
	@include media-breakpoint-up(xl) {
		position: absolute;
		margin-bottom: 0;
	}	
}

.image-container {
	height: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	
	@include media-breakpoint-up(xl) {
		height: auto;
		width: 80%;
		position: relative;
	}	
	
	&.bad-habits {
		position: relative;
		// height: auto;
		// height: 100%;

		@include media-breakpoint-up(xl) {
			// height: 100%;
		}

		.counter {
			position: absolute;
			white-space: nowrap;
			left: 50%;
			bottom: -3%;
			// top: 86%;
			transform: translate(-50%, 0);
			border: 1px solid rgb(79, 79, 255);
			border-radius: 0.5rem;
			background-color: rgba(157, 253, 255, 0.9);
			padding: 0.25rem 1rem;
			// font-size: 20px;
			text-align: center;

			@include media-breakpoint-up(xl) {
				padding: 0.5rem 1.5rem;
				white-space: normal;
				width: auto;
				left: 51%;
				top: 35%;
				bottom: auto;
				transform: translate(-50%, 0);				
			}			
		}		
	}
	&.good-habits {
		position: relative;
		height: 100%;
		@include media-breakpoint-up(xl) {
			height: auto;
		}

		.counter {
			position: absolute;
			white-space: nowrap;
			left: 50%;
			bottom: -3%;
			transform: translate(-50%, 0);
			border: 1px solid rgb(79, 79, 255);
			border-radius: 0.5rem;
			background-color: rgba(157, 253, 255, 0.9);
			padding: 0.25rem 1rem;
			// font-size: 20px;
			text-align: center;
			
			@include media-breakpoint-up(xl) {
				width: 75%;
				padding: 0.5rem 1.5rem;
				white-space: normal;
				width: auto;
				left: 51%;
				bottom: 5%;			
			}				
		}		
	}
}

.left-image {
	max-width: 100%;
	max-height: 100%;
	height: 100%;
	filter: brightness(90%);
	transition: filter .7s;
	// max-height: 9rem;
	
	// @include media-breakpoint-up(lg) {
	// 	max-height: 12rem;
	// }
	@include media-breakpoint-up(xl) {
		max-height: 100vh;
	}
}

.bright-image {
	filter: brightness(120%);
	
}

.flame-container {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100px;
	height: 100px;
}

.tags {
	display: none;

	max-height: 700px;
	position: absolute;
	left: 0;
	bottom: 80px;

	&-item {
		margin-right: 10px;
		margin-bottom: 10px;
	}
}


.right-side {
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 2;

	@include media-breakpoint-up(xl) {
		height: 100vh;
		padding-top: 4rem;
	}	
}

.right-btn {
	width: 380px;
	height: 95px;
	background: #873875;
	border-radius: 25px 0px 0px 25px;
	font-size: 40px;
	line-height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fffbfb;
	border: none;
	align-self: flex-end;
	margin-right: -12px;
}

.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	// overflow: hidden;
	
	@include media-breakpoint-up(xl) {
		height: 100vh;
		justify-content: end;
	}		

	&-wrapper {
		position: relative;
		max-width: 100%;
	}

	&-header {
		position: sticky;
		top: 30vh;
		z-index: 10;

		padding: 0.5rem 1rem;
		// border-radius: 0.5rem 0.5rem 0 0;
		border-radius: 0 0;
		text-align: center;
		color: whitesmoke;
		
		@include media-breakpoint-up(xl) {
			position: relative;
			top: auto;
			padding: 1.5rem 1rem;
			border-radius: 0.5rem 0.5rem 0 0;
		}
	}
	
	&-title {
		
		&-good {
			
			// background: rgba(138, 43,226, 0.8);
			background: #009657;

			// border: 1px solid blueviolet;
		}
		&-bad {

			// background: rgba(138, 43,226, 0.8);
			background: #CE1C51;
			// border: 1px solid blueviolet;
		}
		&-rating {
			
		}
	}




	&-description {
		max-width: 620px;
		font-weight: 500;
		font-size: 30px;
		line-height: 36px;
		text-align: center;
		margin-bottom: 50px;
		&-good {
			color: #302727;
		}
		&-bad {
			color: #fffdfd;
		}
	}
}

.swiper {
	&-container {
		// height: 600px;
		overflow-y: auto;
	}
	&-habit {
		background-color: #fff;
		min-height: 3.5rem;
		height: 100%;
		line-height: 1.25;
		border-bottom: 1px solid lightgrey;
		// border-top: 1px solid black;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding: 0.5rem 0.25rem;

		&-icon {
			margin-right: 1rem;

			img {
				width: 2rem;
			}
		}
	}
	&-habit-tail {
		position: relative;
		background-color: #fff;
		color: #000;
		min-height: 3.5rem;
		height: 100%;
		line-height: 1.25;
		// background-color: rgb(193, 193, 193);
		border-bottom: 1px solid lightgrey;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding: 0.5rem 0.25rem;

		&::after {
			content: 'Вернуть привычку';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: rgba(0,0,0,0.75);
			color: #fff;
		}

		&-text {
			padding-left: 1rem;
		}

		&-bad-icon {
			position: absolute;
			top: 50%;
			transform: translateY(-65%);
			right: 1.2rem;
			z-index: 2;
			width: 1.5rem;
		}
		&-good-icon {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 0.75rem;
			z-index: 2;
			width: 1.5rem;
		}
	}
}
